import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import { AiFillMail } from "@react-icons/all-files/ai/AiFillMail"
import wwsLogo from "../../../assets/images/wws-logo.svg"
import fbIcon from "../../../assets/images/icons/facebook-colored.svg"
import youtubeIcon from "../../../assets/images/icons/youtube-colored.svg"
import instagramIcon from "../../../assets/images/icons/insta-icon.svg"
import spotifyIcon from "../../../assets/images/icons/spotify-colored.svg"

const Footer = ({ siteTitle }) => (
  <FooterMainContainer>
    <FooterContainer>
      <FooterLinkWrapper>
        <FooterDesc>
          {/* <Image src={logo}/> */}
          <NavLogo to="/">
            <Logo src={wwsLogo} />
          </NavLogo>
          <p>
            Writers Way Solutions tells Intensive Management Beyond Global
            Literary Representation
          </p>
        </FooterDesc>
        <FooterLinkItems>
          <FooterLinkTitle> Navigation</FooterLinkTitle>
          <FooterLink to="/">Home</FooterLink>
          <FooterLink to="/publishing-services">Publising Services</FooterLink>
          <FooterLink to="/marketing-services">Marketing Services</FooterLink>
          <FooterLink to="/about">About us</FooterLink>
          {/* <FooterLink to="/vlogs">Vlogs</FooterLink> */}
          <FooterLink to="/contact">Contact us</FooterLink>
        </FooterLinkItems>
        {/* <FooterLinkItems>
          <FooterLinkTitle>Services</FooterLinkTitle>
          <FooterLink to="/services">Film Rights Representation</FooterLink>
          <FooterLink to="/services">Global Licensing</FooterLink>
          <FooterLink to="/services">Pre-Acquisiton Services</FooterLink>
        </FooterLinkItems> */}
        <FooterLinkItems>
          <FooterLinkTitle> Contact Us </FooterLinkTitle>
          <FooterLink to="/contact">
            <AiFillMail /> customersupport@writerswaysolutions.com
          </FooterLink>
          <FooterLink to="/contact">Call: 1-888-666-4258</FooterLink>
          <div
            css={`
              display: flex;
              align-items: center;
              gap: 1rem;
            `}
          >
            <IconWrapper
              href="https://www.youtube.com/@WritersWaySolutions."
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon src={youtubeIcon} />
            </IconWrapper>
            <IconWrapper
              href="https://www.facebook.com/profile.php?id=61555616772326"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon src={fbIcon} />
            </IconWrapper>
            <IconWrapper
              href="https://open.spotify.com/show/3Mg1BrXJApuM7Gk5a86dAV"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon src={spotifyIcon} />
            </IconWrapper>
          </div>
        </FooterLinkItems>
        {/* </FooterLinkWrapper>
      <FooterLinkWrapper> */}

        {/* <FooterLinkItems>
              <FooterLinkTitle> Social Media</FooterLinkTitle>
              <FooterLink to="/"><SiFacebook/> Facebook</FooterLink>
              <FooterLink to="/"><SiTwitter/> Twitter</FooterLink>
              <FooterLink to="/"><SiLinkedin/> LinkedIn</FooterLink>
              <FooterLink to="/"><SiInstagram/> Instagram</FooterLink>
          </FooterLinkItems> */}
      </FooterLinkWrapper>
    </FooterContainer>
    {/* <Divider></Divider> */}
    {/* <Wrapper> */}
    <FooterCopyRight>
      <p>
        <span>&#169;</span> Copyright 2023 by Writers Way Solutions.
        <br /> All Rights Reserved
      </p>
      <PolicyWrapper>
        <LinkText to="/compliance-policy">Privacy Policy · </LinkText>
        <LinkText to="/terms-cons">Terms & Conditions</LinkText>
      </PolicyWrapper>
    </FooterCopyRight>

    {/* </Wrapper> */}
  </FooterMainContainer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer

// const Wrapper = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// `

const PolicyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.1rem;
`

const LinkText = styled(Link)`
  text-decoration: none;
  font-size: clamp(0.5rem, 3vw, 0.8rem);
  text-align: right;
  cursor: pointer;
  color: #fff;
`

// const Divider = styled.div`
//   height: 1px;
//   width: 57%;
//   background: #FFF;
//   margin-left: auto;
//   margin-right: auto;
// `

const FooterMainContainer = styled.div`
  background-image: radial-gradient(rgb(27, 84, 130) 40.4%, transparent 40.4%),
    radial-gradient(rgb(27, 84, 130) 40.4%, transparent 40.4%);
  background-position: 0px 0px, 4px 4px;
  background-size: 8px 8px;
  background-color: ${props => props.theme.colors.accent7};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 5rem calc((100vw - 1200px) / 2);
  box-sizing: border-box;
  gap: 3rem;
  padding-bottom: 0;

  @media screen and (max-width: 1249px) {
    padding: 2rem 1.5rem;
    gap: 1rem;
  }
`
const FooterCopyRight = styled.div`
  /* padding: 5rem calc((100vw - 1350px) / 2); */
  /* padding: 5rem 0; */
  display: flex;
  /* flex-direction: column; */
  /* width: 84%; */
  /* margin-left: auto;
  margin-right: auto; */
  border-top: 1px solid lightgrey;
  justify-content: space-between;

  width: 100%;
  padding: 4rem 0;
  padding-bottom: 5rem;
  gap: 1rem;
  @media screen and (max-width: 767px) {
    align-items: center;
    flex-direction: column;
  }
  @media screen and (max-width: 400px) {
    padding: 1rem;
  }
  p {
    /* width: 100%; */
    ${"" /* font-family: "Roboto", sans-serif; */}
    font-size: clamp(0.5rem, 3vw, .8rem);
    color: ${props => props.theme.colors.light3};
    width: unset;
    text-align: left;
    white-space: nowrap;
    line-height: 24px;

    br {
      display: inline;

      @media screen and (min-width: 400px) {
        display: none;
      }
    }
    @media screen and (max-width: 400px) {
      text-align: center;
    }
  }
`
const FooterContainer = styled.div`
  /* padding: 5rem calc((100vw - 1100px) / 2); */
  ${
    "" /* display:grid;
grid-template-columns: repeat(2, 1fr); */
  }

  display:flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  ${"" /* background: ${props => props.theme.colors.main3}; */}
  background-image: 
    radial-gradient(rgb(27, 84, 130) 40.4%, transparent 40.4%),
    radial-gradient(rgb(27, 84, 130) 40.4%, transparent 40.4%);
  background-position: 0px 0px, 4px 4px;
  background-size: 8px 8px;
  background-color: ${props => props.theme.colors.accent7};

  /* @media screen and (max-width: 768px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  } */

  @media screen and (max-width: 768px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  @media screen and (max-width: 295px) {
    ${"" /* grid-template-columns: 1fr; */}
    flex-direction:column;
  }
`
const FooterDesc = styled.div`
  padding: 0.5rem 2rem;
  width: 20%;
  display: flex;
  flex-direction: column;
  ${"" /* margin-left:1rem; */}
  ${"" /* justify-content:center; */}
 align-items:center;
  ${"" /* text-align:start; */}
  p {
    ${"" /* font-family: "Roboto", sans-serif; */}
    font-size: clamp(0.7rem, 3vw, 1rem);
    font-style: italic;
    font-weight: 600;
    color: ${props => props.theme.colors.light3};
    ${
      "" /* text-align: justify;
    width: 100%; */
    }
  }
  @media screen and (max-width: 980px) {
    margin-left: 1rem;
  }
  @media screen and (max-width: 820px) {
    margin-left: 0;
    width: 60%;
    p {
      line-height: 20px;
    }
  }
  @media screen and (max-width: 400px) {
    padding: 1rem;
  }
`

const NavLogo = styled(Link)`
  display: flex;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.accent2};
  font-weight: 900;
  span {
    color: ${props => props.theme.colors.accent1};
  }
`
export const Logo = styled.img`
  height: 100%;
  width: 200px;
`
// const Image = styled.img`
//   height: 60%;
//   width: 100%;
//   margin-bottom: 0.5rem;
//   padding: 0.5rem;
// `
const FooterLinkWrapper = styled.div`
  ${
    "" /* display:grid;
  grid-template-columns: repeat(2, 1fr); */
  }
  display:flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 820px) {
    ${"" /* grid-template-columns: 1fr; */}
    flex-direction:column;
    justify-content: center;
    align-items: center;
  }
`
const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 2rem;
  @media screen and (max-width: 820px) {
    padding: 1rem;
    align-items: center;
    justify-content: center;
  }
`
const FooterLinkTitle = styled.h2`
  font-size: clamp(0.8rem, 5vw, 1.2rem);
  margin-bottom: 16px;
  color: #2a8ae5;
`
const FooterLink = styled(Link)`
  display: flex;
  gap: 0.3rem;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: clamp(0.6rem, 3vw, 0.9rem);
  color: ${props => props.theme.colors.light3};
  ${"" /* font-family: "Roboto", sans-serif; */}

  &:hover {
    color: ${props => props.theme.colors.light3};
    transition: 0.3s ease-in-out;
  }
`

const Icon = styled.img`
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  /* aspectratio: ; */
`

const IconWrapper = styled.a`
  cursor: pointer;
`
