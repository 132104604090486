import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
// import { Button } from "../../reusableComponents/Button"
import wwsLogo from '../../../assets/images/wws-logo.svg';
import { useLocation } from "@reach/router";

import {
  Nav,
  NavLogo,
  MenuIcon,
  Bars,
  Logo,
  NavMenu,
  BarsTimes,
  // NavBtn,
  NavLink,
  LinkContainer,
  DropDown,
  DropdownContent,
  DropdownLink,
  MenuLink,
  MenuItemLink
} from "./HeaderElements"
import {gsap} from "gsap"

const Header = ({ siteTitle }) => {
  const [clicked, setClicked] = useState(false)
  const location = useLocation();

  const openNav = e => {
    e.preventDefault()
    setClicked(prevState => !prevState)
  }

  let container = useRef(null)
  let nav = useRef(null)

  let tl = new gsap.timeline({delay : 1.2})

  useEffect(() => {
    let mounted = true

    const logo = container.firstElementChild
    // const button = container.lastElementChild

    const homeNav = nav.children[0]
    const serviceNav = homeNav.nextSibling
    const aboutNav = serviceNav.nextSibling
    const bookTrailer = aboutNav.nextSibling
    // const blogNav = aboutNav.nextSibling
    const contactNav = bookTrailer.nextSibling

    if (mounted) {
      gsap.to(container, { duration: 1, css: { visibility: "visible" } })
      tl.from(
        [
          logo,
          homeNav,
          serviceNav,
          aboutNav,
          bookTrailer,
          // blogNav,
          contactNav,
          // button,
        ],
        { duration: 1, opacity:0, y: -50, ease: "power3.out", stagger: .15 }
      )
    }

    return function cleanup() {
      mounted = false
    }
  },[])

  return (
    <Nav className="headerContainer" ref={el => (container = el)}>
      <NavLogo to="/">
        <Logo src={wwsLogo}/>
      </NavLogo>
      <MenuIcon onClick={openNav} navMenu={clicked}>
        {clicked ? <BarsTimes /> : <Bars />}
      </MenuIcon>
      <NavMenu navMenu={clicked} ref={el => (nav = el)}>
       <LinkContainer onClick={openNav}>
          <NavLink to="/" className="headerLink" activeClassName="active">
            Home
          </NavLink>
        </LinkContainer>
        {/* <LinkContainer onClick={openNav}>
          <NavLink to="/services" className="headerLink" activeClassName="active">
            Services
          </NavLink>
        </LinkContainer> */}
        <DropDown>
          <LinkContainer>
            <MenuLink className={location.pathname === "/publishing-services" || location.pathname === '/marketing-services' ? "headerLink active" : "headerLink"}>
              Services
            </MenuLink>
          </LinkContainer>
          <DropdownContent className="return-to-top">
            <DropdownLink>
              <MenuItemLink to="/publishing-services" className="dropdown-headerLink" activeClassName="active">
                Publishing Services
              </MenuItemLink>
            </DropdownLink>
            <DropdownLink>
              <MenuItemLink to="/marketing-services" className="dropdown-headerLink" activeClassName="active">
                Marketing Services
              </MenuItemLink>
            </DropdownLink>
          </DropdownContent>
        </DropDown>
        <LinkContainer onClick={openNav}>
          <NavLink to="/book-trailers" className="headerLink" activeClassName="active">
            Book Trailers
          </NavLink>
          </LinkContainer>
        <LinkContainer onClick={openNav}>
          <NavLink to="/about" className="headerLink" activeClassName="active">
            About Us
          </NavLink>
          </LinkContainer>
          
        {/* <LinkContainer onClick={openNav}>
          <NavLink to="/vlogs" className="headerLink" activeClassName="active">
            Vlogs
          </NavLink>
        </LinkContainer> */}
        <LinkContainer onClick={openNav}>
          <NavLink to="/contact" className="headerLink" activeClassName="active">
            Contact Us
          </NavLink>
        </LinkContainer>
        {/* <NavLink
          to="/submission"
          className="headerLink"
          activeClassName="active"
          css={`
            display: none;
            @media screen and (max-width: 768px) {
              display: block;
              margin-top: 1.5rem;
            }
          `}
        >
          Submit now
        </NavLink> */}
      </NavMenu>
      {/* <NavBtn>
        <Button to="/submission" round="true">
          Submit now
        </Button>
      </NavBtn> */}
    </Nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
