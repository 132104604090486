import styled, { keyframes } from "styled-components"
import { FaBars } from "@react-icons/all-files/fa/FaBars"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Link } from "gatsby"

const accent = props => props.theme.colors.accent1
const accent2 = props => props.theme.colors.accent2
const white = props => props.theme.colors.light3
const dark = props => props.theme.colors.dark1


export const Nav = styled.div`
  visibility: hidden;
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px calc((100vw - 1200px) / 2);
  padding-top: 2rem;
  @media screen and (max-width: 768px) {
    padding: 1rem calc((100vw - 1200px) / 2);
  }
  z-index: 10;
  position:relative;
`

export const NavLogo = styled(Link)`
  display: flex;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: ${accent2};
  font-weight: 900;

  span{
    color:${accent};
  }
  @media screen and (max-width: 1274px) {
    margin-left: 24px;
  } ;
  @media screen and (max-width: 768px) {
    transform: translate(0px, 9px);
  } ;
  @media screen and (max-width: 240px) {
    visibility:hidden; 
  } ;
`

export const Logo = styled.img`
  height: 80px;
  width: 280px;
  @media screen and (max-width: 807px) {
    width: 160px;
    height: 50px;
  };
`

export const MenuIcon = styled.div`
  display: none;

  @media screen and (max-width: 968px) {
    z-index: 11;
    display: block;
    position: fixed;
    top: 4.5%;
    right: 4%;
    transform: "translate(-50%,90%)";
    transition: 0.3s;
    font-size: 1.8rem;
    cursor: pointer;
  }
`

export const Bars = styled(FaBars)`
  color: ${accent};
`
export const BarsTimes = styled(FaTimes)`
  color:${white};
`

export const NavMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  @media screen and (max-width: 968px) {
    position: fixed;
    justify-content: center;
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    top: 0;
    right: ${({ navMenu }) => (navMenu ? "0" : "-100%")};
    transition: 0.3s ease-in;
    background:${dark};
    z-index: 10;
    margin-right: 0;
  }
`
export const NavBtn = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 1274px) {
    margin-right: 24px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`
export const LinkContainer = styled.div`
  height:100%;

  @media screen and (max-width: 968px) {
    height:80px;
    ${'' /* background:red; */}
  }
`

// Define the animation
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const DropDown = styled.div`
  position: relative;
  display: inline-block;
  width: auto;

  &:hover .dropdown-content {
    display: block;
  }
`;

export const DropdownContent = styled.div`
  display: block;
  position: absolute;
  ${'' /* background-color: ${props => props.theme.colors.main3}; */}
  ${'' /* background-color: #4B4BFF; */}
  background-color: #fff;
  min-width: 210px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: -1;
  left: 1rem;
  top: -6.5rem;
  transition: all .3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  border-radius: 8px;
  overflow: hidden;

  ${DropDown}:hover & {
    opacity: 1;
    display: block;
    top: 4.5rem;
    visibility: visible;
  }
`;

export const DropdownLink = styled.span`
  text-decoration: none;
  display: block;

  &:hover {
    background-color: ${props => props.theme.colors.accentSkyBlue};
  }
`;

export const NavLink = styled(Link)`
  font-weight: 600;
  font-family: ${props => props.theme.fonts.secondary} !important;
  font-size: 1rem;
  color: ${white};
  display: flex;
  align-items: center;
  padding: 0 1rem;
  box-sizing: border-box;
  height:80px;
  transition: 0.1s ease-in-out !important;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.accent3};
  }

  ${'' /* &.active {
    color: ${props => props.theme.colors.accent1};
  } */}
  @media screen and (max-width: 768px) {
    ${'' /* color: ${white}; */}
    justify-content: center;
    cursor: pointer;
    ${'' /* &:hover {
      color: ${white};
      font-size: 1.5rem;
      transform: translateY(-1px);
    } */}
  }
`;

export const MenuItemLink = styled(Link)`
  font-weight: normal;
  font-size: 1rem;
  color: ${props => props.theme.colors.dark2};
  display: flex;
  align-items: center;
  padding: 0 1rem;
  box-sizing: border-box;
  height:80px;
  transition: 0.1s ease-in-out !important;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.light3};
  }

  ${'' /* &.active {
    color: ${props => props.theme.colors.accent1};
  } */}
  @media screen and (max-width: 768px) {
    ${'' /* color: ${white}; */}
    justify-content: center;
    cursor: pointer;
    ${'' /* &:hover {
      color: ${white};
      font-size: 1.5rem;
      transform: translateY(-1px);
    } */}
  }
`;

export const MenuLink = styled.span`
  font-weight: 600;
  font-family: ${props => props.theme.fonts.secondary} !important;
  color: ${white};
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-size: 1rem;
  height:80px;
  transition: 0.1s ease-in-out !important;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    justify-content: center;
    cursor: pointer;
  }
`;
