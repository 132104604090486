import React from "react"
import { ThemeProvider } from "styled-components"
import Theme from "./src/themes/theme"
import Layout from "./src/components/layout"
import { GlobalStyle } from "./src/themes/global-style"

export const wrapPageElement = ({ element }) => (
  <ThemeProvider theme={Theme}>
    <GlobalStyle />
    <Layout>{element}</Layout>
  </ThemeProvider>
)
